@font-face {
  font-family: "Museo-700";
  src: url("../assets/fonts/Museo\ Sans\ Cyrl\ 700.ttf");
}

@font-face {
  font-family: "Museo-300";
  src: url("../assets/fonts/Museo\ Sans\ Cyrl\ 300.ttf");
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 35px;
}

.slogan {
  margin-bottom: 10px;
  h3 {
    font-family: "Museo-700";
    font-size: 34px;
    background-color: red;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(254, 76, 0, 1) 100%
    );
    background-size: 100%;
    background-repeat: repeat;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  p {
    font-family: "Museo-300";
    font-size: 18px;
    background-color: red;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(254, 76, 0, 1) 100%
    );
    background-size: 100%;
    background-repeat: repeat;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
}
.row_cards {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.card {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  color: white;

  cursor: pointer;
  z-index: 100;
  width: 150px;
  height: 150px;
  padding: 20px;
  margin-left: 5px;
  margin-right: 5px;
  opacity: 0.7;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );

  &:hover {
    opacity: 1;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 100%
    );
    // border-right: 0.1rem solid rgba(255, 255, 255, 0.3);
    border-width: 0.1rem;
    border-style: solid;
    border-image-slice: 1;
    border-top: none;
    border-left: none;
    border-image-source: linear-gradient(
      135deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 255, 255, 0.7) 100%
    );
  }

  h1 {
    font-size: 50px;
    margin-top: 15px;
    font-family: "Museo-700";
  }

  p {
    font-family: "Museo-300";
  }
}

@media only screen and (max-width: 920px) {
  .wrapper {
    align-items: center;

    flex-direction: column;
    margin-top: 0;
  }

  .slogan {
    text-align: center;
  }
}
