* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;

  -webkit-tap-highlight-color: transparent;
}

html,
body {
  overflow: hidden;
}
